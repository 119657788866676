import * as React from 'react'
import Layout from '@/components/Layout'
import SEO from '@/components/seo'
import { useLocation } from '@reach/router'
import Breadcrumbs from '@/components/Common/Breadcrumbs'
import AboutHome from '@/components/Home/About'
import ServicesC from '@/components/Services'

const ServicesPage = () => {
  return (
    <Layout>
      <SEO title="Services" canonical={`${useLocation().host}`} />
      <Breadcrumbs title="Services" />
      <AboutHome />
      <ServicesC />
    </Layout>
  )
}

export default ServicesPage
